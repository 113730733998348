// Frameworks
import React, { useEffect } from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { useEagerConnect } from "../hooks/hooks"

// Layout Components
import "./layout.css"
import SEO from "./seo"

const customTheme = {}

// Layout Wrapper
const Layout = ({ children }) => {
  useEagerConnect()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const _goHome = () => {
    navigate(`/`)
  }

  // console.log(data)

  //To setup/show usersnap
  useEffect(() => {
    window.onUsersnapCXLoad = function(api) {
      api.init()
    }
    var script = document.createElement("script")
    script.defer = 1
    script.src =
      "https://widget.usersnap.com/global/load/58b983d9-3c2d-4463-8f55-7193d8f08aeb?onload=onUsersnapCXLoad"
    document.getElementsByTagName("head")[0].appendChild(script)
  }, [])

  return (
    <div className="flex flex-col h-screen">
      <SEO title="AxieTree" />
      <div className="snap-y bg-background flex flex-col flex-1 min-h-0 flex-grow">
        <main className="flex flex-col flex-grow">{children}</main>
      </div>
    </div>
  )
}

export default Layout
